import React from 'react'

export default function Footer() {
    return (
        <footer className="footer">
        <div className="container-fluid">
          <span className="text-muted"> &copy; Jorgen Tuck 2021 </span>
        </div>
      </footer>
    )
}
